import { ElementRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ClientForm } from '../../models/ClientForm';
import ClientFormService from '../../services/ClientFormService';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import RiskUtils, { RiskRating } from '../../utils/RiskUtils';
import { FormType } from '../../models/FormTypes';
import FormHeader from '../../components/form-header/FormHeader';
import FormBreadCrumb from '../../components/form/FormBreadCrumb';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import hasFormRole from '../../utils/FormPermissionUtils';
import { Roles } from '../../models/Role';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import {
  currentActivityCommentsStats,
  currentFormTypeAtom,
  currentFormUsersAtom,
  formSectionUsersAtom,
  viewDistributionLogsAtom,
} from '../../recoil/atoms/Forms';
import FormRendererV2 from '../../components/form/renderer/FormRendererV2';
import { ActionHighlightInfo, FormAction, FormSection, PeriodicReviewChecklistItemInstance, Progress } from '../../models/Form';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import User from '../../models/User';
import usePermissions from '../../hooks/permissions/usePermissions';
import Comments from '../../components/comments/Comments';
import { SignalRCommentProvider } from '../../contexts/signalR/CommentContext';
import { TabStrip } from '../../components/shared/tab-strip/TabStrip';
import { useTranslation } from 'react-i18next';
import { FormRendererDesignContext, FormRendererDesignDefaults } from '../../contexts/FormRendererDesignContext';
import { EventSystem } from '../../events/EventSystem';
import { ChevronIcon, ChevronType } from '../../components/shared/icon/ChevronIcon';
import CommentEvent from '../../events/QuestionCommentEvent';
import { OpenFormSectionEvent } from '../../events/OpenFormSectionEvent';
import { isInSuspenseAtom } from '../../recoil/atoms/App';
import { useFetchAssociations } from '../../hooks/useFetchAssocations';
import { DocumentResponse } from '../../models/Document';
import DocumentFileViewer from './DocumentFileViewer';
import { FileUtils } from '../../utils/FileUtils';
import DocumentBreadCrumb from '../../components/form/DocumentBreadCrumb';
import { Access } from '../../models/Access';
import ViewerLeftNav from '../../components/form/viewer/ViewerLeftNav';
import { useLayout } from '../../contexts/LayoutContext';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import DateUtils from '../../utils/DateUtils';
import ClientFormVersionHistory from '../../models/ClientFormVersionHistory';
import { Placeholders } from '../../models/Placeholders';
import ThumbsUpIcon from '../../components/shared/icon/ThumbsUpIcon';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import CheckIcon from '../../components/shared/icon/CheckIcon';
import XIcon from '../../components/shared/icon/XIcon';
import RiskModal from '../../components/risk/RiskModal';
import RiskService from '../../services/RiskService';
import { Risk, RiskCalculated, RiskRatingKeys, RiskStatus, RiskType } from '../../models/Risk';
import QuestionRiskCreatedEvent from '../../events/QuestionRiskCreatedEvent';
import { conditionToText } from '../../components/form/EvaluationEngine';
import { useDetectVisible } from '../../hooks/useDetectVisible';
import { DistributionMemberStatus } from '../../models/Distribution';
import { DistributionResponse } from '../../models/Distribution';
import DistributionLogs from '../../components/distribution/DistributionLogs';
import FormUtils from '../../utils/FormUtils';

const FormPreview: FC = () => {
  const [clientForm, setClientForm] = useState<ClientForm | null>(null);
  const [formStartDate, setFormStartDate] = useState<Date | null>(null);
  const [formStatus, setFormStatus] = useState<ClientFormStatus>(ClientFormStatus.NotStarted);
  const [commentsCollapsed, setCommentsCollapsed] = useLocalStorageState('form-viewer-right-collapsed', true);
  const [leftNavCollapsed, setLeftNavCollapsed] = useLocalStorageState('form-viewer-left-collapsed', false);
  const [userHistories, setUserHistories] = useState<ClientFormVersionHistory[]>([]);
  const { leftNavRef } = useLayout();
  const viewDistributionLogs = useRecoilValue(viewDistributionLogsAtom);

  const { t } = useTranslation(['form', 'common', 'form-builder', 'risk', 'activity-type', 'distribution']);

  const formUsers = useRecoilValue(currentFormUsersAtom);
  const formSectionUsers = useRecoilValue(formSectionUsersAtom);

  const params = useParams<{ formId: string }>();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const formRenderer = useRef<ElementRef<typeof FormRendererV2>>(null);

  const currentUser = useRecoilValue(currentUserAtom);
  const hasPermission = usePermissions();
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const setCurrentFormType = useSetRecoilState(currentFormTypeAtom);

  const { isVisible: bottomDetectorVisible, detector: bottomDetector } = useDetectVisible();

  useEffect(() => {
    const handler = (evt: { clientFormId: string; versions: ClientFormVersionHistory[] }) => {
      if (evt.clientFormId !== params.formId) return;

      setUserHistories(evt.versions);
    };
    EventSystem.listen('user-state-histories-loaded', handler);
    return () => {
      EventSystem.stopListening('user-state-histories-loaded', handler);
    };
  }, [params.formId]);

  useEffect(() => {
    return () => setCurrentFormType(null);
  }, [setCurrentFormType]);

  const fetchForm = useCallback(() => {
    ClientFormService.getForm(params.formId as string).then((res) => {
      setCurrentFormType(res.data.type);
      setClientForm(res.data);
      setFormStatus(res.data.status);
      if (res.data.form.isSystem && res.data.form.type === FormType.Document) {
        FileUtils.getFileUrl((res.data as DocumentResponse).fileId).then((url) => {
          setSelectedFile(url);
        });
      }
    });
  }, [params.formId, setCurrentFormType]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  const shouldCalculateRisk = useMemo(() => formStatus !== ClientFormStatus.NotStarted && formStatus !== ClientFormStatus.InProgress, [formStatus]);

  // Calculated Risk per question
  const [actionRiskAndText, setActionRiskAndText] = useState<Record<string, RiskCalculated>>({});
  const [actionRisks, setActionRisks] = useState<Record<string, number>>({});
  useEffect(() => {
    if (!clientForm) {
      return;
    }

    const calculateRiskAndText = async (action: FormAction, section: FormSection): Promise<RiskCalculated | null> => {
      const actionCalculatedRisk = RiskUtils.calculateRiskForAction(action, clientForm.form);
      if (!actionCalculatedRisk.matchedCondition) return null;
      const riskScore = shouldCalculateRisk ? actionCalculatedRisk.score : -1;
      const riskRating = riskScore > 3 ? RiskRating.CRITICAL_RISK : (riskScore as RiskRating);
      const title = await conditionToText(actionCalculatedRisk.matchedCondition || '', clientForm.clientId, t(RiskRatingKeys[riskRating]), action, t);
      return {
        id: action.id,
        actionId: action.id,
        sectionId: section.id,
        actionTranslations: action.translations || {},
        clientFormId: clientForm.id,
        score: riskScore,
        title: title,
        type: RiskType.calculated,
        lastModifiedUtc: clientForm.statusUtc || '', // use the statusUtc as this will be the date when the conditional risk got flagged
        actionIndex: 0, // this will get calculated when combining the risks
        // the following properties are not needed, but required in type
        ownerId: '',
        status: RiskStatus.identified,
        matrixVerticalId: '',
        matrixHorizontalId: '',
        riskCategoryId: '',
        friendlyId: -1,
        riskRating: riskRating,
        clientFormSubtitle: '',
        placeholders: clientForm.placeholders || {},
        statusUtc: '',
        documentNumber: null,
        templateModuleTranslations: {},
      };
    };

    const calculateAllRisks = async () => {
      const riskAndTextResult: Record<string, RiskCalculated> = {};
      const result: Record<string, number> = {};
      for (const section of clientForm.form.sections) {
        for (const action of section.actions) {
          const calculatedRisk = await calculateRiskAndText(action, section);
          if (calculatedRisk && calculatedRisk.score > 0) {
            riskAndTextResult[action.id] = calculatedRisk;
            result[action.id] = calculatedRisk.score;
          }
        }
      }
      setActionRiskAndText(riskAndTextResult);
      setActionRisks(result);
    };

    calculateAllRisks();
  }, [clientForm, shouldCalculateRisk, t]);

  const progress = useMemo<Progress>(
    () =>
      !clientForm
        ? { totalAnswers: 0, totalRequired: 0 }
        : { totalRequired: clientForm.totalRequiredActions, totalAnswers: clientForm.totalRequiredAnswers },
    [clientForm],
  );

  useEffect(() => {
    if (!clientForm) {
      return;
    }
    setFormStartDate(clientForm.startDateUtc ? new Date(clientForm.startDateUtc) : null);
    setFormStatus(clientForm.status);
  }, [clientForm]);

  const onRename = useCallback((newSubtitle: string) => {
    setClientForm((prev) => (prev === null ? null : { ...prev, subtitle: newSubtitle }));
  }, []);

  const onAccessTypeChange = useCallback((value: Access) => {
    setClientForm((prev) => (prev === null ? null : { ...prev, accessType: value }));
  }, []);

  const visibleSections = useMemo(() => {
    if (!clientForm) {
      return [];
    }

    return clientForm.form.sections.filter((section) => {
      const hasPermissionToView =
        hasPermission(Roles.Management) ||
        hasFormRole(
          [
            ClientFormUserRole.Owner,
            ClientFormUserRole.Contributor,
            ClientFormUserRole.Approver,
            ClientFormUserRole.Validator,
            ClientFormUserRole.Viewer,
          ],
          ClientFormService.getCurrentFormUser(formUsers, currentUser as User),
        ) ||
        hasFormRole(
          [ClientFormUserRole.Contributor, ClientFormUserRole.Approver, ClientFormUserRole.Viewer],
          ClientFormService.getCurrentFormUser(formSectionUsers, currentUser as User, section.id),
        );

      return hasPermissionToView;
    });
  }, [clientForm, hasPermission, formUsers, currentUser, formSectionUsers]);

  const currentFormUser = useMemo(() => {
    return ClientFormService.getCurrentFormUser(formUsers, currentUser as User);
  }, [formUsers, currentUser]);

  const commentStats = useRecoilValue(currentActivityCommentsStats);
  const unreadMainComments = useMemo(() => {
    return commentStats.unreadCommentsCount;
  }, [commentStats.unreadCommentsCount]);

  const [selectedCommentSection, setSelectedCommentSection] = useState<string>(visibleSections[0]?.id);

  useEffect(() => {
    const handleCommentOpen = (event: CommentEvent) => {
      setSelectedCommentSection(event.sectionId);
      //setCommentsCollapsed(false);
    };

    EventSystem.listen('question-comment-open', handleCommentOpen);
    EventSystem.listen('question-comment-new', handleCommentOpen);

    return () => {
      EventSystem.stopListening('question-comment-open', handleCommentOpen);
      EventSystem.stopListening('question-comment-new', handleCommentOpen);
    };
  }, [setCommentsCollapsed]);

  // open comments via url/event
  const [selectedStepFromEvent, setSelectedStepFromEvent] = useState<OpenFormSectionEvent | undefined>();
  useEffect(
    function changeSectionIndexOnEvent() {
      const handler = (event: OpenFormSectionEvent) => {
        const index = visibleSections.findIndex((x) => x.id === event.sectionId) ?? -1;
        if (!event.sectionId || index === -1) {
          return;
        }

        setSelectedCommentSection(event.sectionId);
        setSelectedStepFromEvent(event);
      };

      EventSystem.listen('open-form-section', handler);
      return () => {
        EventSystem.stopListening('open-form-section', handler);
        setSelectedStepFromEvent(undefined);
      };
    },
    [visibleSections],
  );

  const isSuspense = useRecoilValue(isInSuspenseAtom);

  useEffect(() => {
    if (!isSuspense && selectedStepFromEvent) {
      if (selectedStepFromEvent.sourceId) {
        const result = setTimeout(() => {
          EventSystem.fireEvent('question-comment-open', {
            sourceId: selectedStepFromEvent.sourceId || '',
            sectionId: selectedStepFromEvent.sectionId,
          });
        }, 100);
        return () => clearTimeout(result);
      }
    }
  }, [isSuspense, selectedStepFromEvent]);

  const onChecklistChanged = useCallback((checklist: PeriodicReviewChecklistItemInstance[]) => {
    setClientForm(
      (prev) =>
        prev && {
          ...prev,
          periodicReviewChecklist: checklist,
        },
    );
  }, []);

  const { associations } = useFetchAssociations(clientForm?.id, true);

  const isDocumentFile = useMemo(
    () => clientForm?.type === FormType.Document && clientForm?.form.isSystem && selectedFile,
    [clientForm?.form.isSystem, clientForm?.type, selectedFile],
  );

  const rightPaneRef = useRef<HTMLDivElement>(null);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const docViewerMaxWidth = useMemo(
    () =>
      `calc(100vw - ${
        (leftNavRef.current?.offsetWidth || 75) +
        (leftNavCollapsed ? 0 : leftPaneRef.current?.offsetWidth || 300) +
        (commentsCollapsed ? 0 : rightPaneRef.current?.offsetWidth || 344)
      }px)`,
    [commentsCollapsed, leftNavCollapsed, leftNavRef],
  );

  const documentViewerStyle = useMemo(() => ({ maxWidth: docViewerMaxWidth }), [docViewerMaxWidth]);

  const [reviewedIcon, reviewedText] = useMemo(() => {
    const parsedUserHistories = userHistories
      .slice(
        0,
        userHistories.findIndex((x) => !x.acceptedStatus),
      )
      .filter(
        (x) =>
          x.status === (clientForm?.form.requiresApproval ? ClientFormStatus.SubmittedForApproval : ClientFormStatus.Completed) && x.acceptedStatus,
      )
      .filter((x) => x.activityPlaceholders[Placeholders.PRIMARY_USER])
      .map(
        (x) =>
          [
            x.activityPlaceholders[Placeholders.PRIMARY_USER].id as string,
            <div key={x.id} className="mb-1">
              <span>
                {t(clientForm?.form.requiresValidationSignature ? 'reviewed-by-signed' : 'reviewed-by', {
                  name: x.activityPlaceholders[Placeholders.PRIMARY_USER].userName,
                })}
              </span>{' '}
              <span className="text-gray-2">{DateUtils.formatDateTime(new Date(x.createdUtc || ''), false, true)}</span>
            </div>,
          ] as const,
      );
    const userIds = parsedUserHistories.map((x) => x[0]);
    const alreadyHappend = parsedUserHistories.map((x) => x[1]);

    const toHappenStill =
      clientForm?.status !== ClientFormStatus.SubmittedForValidation
        ? []
        : formUsers
            .filter((x) => x.role === ClientFormUserRole.Validator && !userIds.includes(x.id))
            .map((x) => <div key={x.id}>{t('awaiting-review', { name: x.fullName })}</div>);

    let icon = <InfoIcon className="bg-gray-1 h-8 w-8 rounded-full p-2 text-white" />;
    if (alreadyHappend.length > 0) {
      icon = <ThumbsUpIcon className="bg-gray-1 h-8 w-8 rounded-full p-2 text-white" />;
    }

    return [icon, alreadyHappend.concat(toHappenStill)];
  }, [clientForm?.form.requiresApproval, clientForm?.form.requiresValidationSignature, clientForm?.status, formUsers, t, userHistories]);

  const [approvalIcon, approvalText] = useMemo(() => {
    const parsedUserHistories = userHistories
      .slice(
        0,
        userHistories.findIndex((x) => !x.acceptedStatus),
      )
      .filter((x) => clientForm?.form.requiresApproval && x.status === ClientFormStatus.Completed && x.acceptedStatus)
      .filter((x) => x.activityPlaceholders[Placeholders.PRIMARY_USER])
      .map(
        (x) =>
          [
            x.activityPlaceholders[Placeholders.PRIMARY_USER].id as string,
            <div key={x.id} className="mb-1">
              <span>
                {t(clientForm?.form.requiresApprovalSignature ? 'approved-by-signed' : 'approved-by', {
                  name: x.activityPlaceholders[Placeholders.PRIMARY_USER].userName,
                })}
              </span>{' '}
              <span className="text-gray-2">{DateUtils.formatDateTime(new Date(x.createdUtc || ''), false, true)}</span>
            </div>,
          ] as const,
      );
    const userIds = parsedUserHistories.map((x) => x[0]);
    const alreadyHappend = parsedUserHistories.map((x) => x[1]);

    const toHappenStill =
      clientForm?.status !== ClientFormStatus.SubmittedForApproval
        ? []
        : formUsers
            .filter((x) => x.role === ClientFormUserRole.Approver && !userIds.includes(x.id))
            .map((x) => <div key={x.id}>{t('awaiting-approval', { name: x.fullName })}</div>);

    let icon = null;
    if (alreadyHappend.length > 0) {
      icon = <CheckIcon className="bg-semantic-1 h-8 w-8 rounded-full p-2 text-white" />;
    }

    return [icon, alreadyHappend.concat(toHappenStill)];
  }, [clientForm?.form.requiresApproval, clientForm?.form.requiresApprovalSignature, clientForm?.status, formUsers, t, userHistories]);

  const [otherNoticesIcon, otherNoticesText] = useMemo(() => {
    const texts = [];

    const distribution = (clientForm as DocumentResponse)?.distribution;
    if (distribution) {
      const selfMember = distribution.members.find((x) => x.memberId === currentUser?.id);
      if (selfMember?.status === DistributionMemberStatus.Acknowledged) {
        texts.push(
          <div key={texts.length} className="mb-1">
            <span>
              {t('distribution:acknowledge.notice', {
                date: DateUtils.formatDateTime(new Date(selfMember.statusUtc), false, true),
              })}
            </span>
          </div>,
        );
      } else if (selfMember?.status === DistributionMemberStatus.Signed) {
        texts.push(
          <div key={texts.length} className="mb-1">
            <span>
              {t('distribution:acknowledge.notice-signed', {
                date: DateUtils.formatDateTime(new Date(selfMember.statusUtc), false, true),
              })}
            </span>
          </div>,
        );
      }
    }

    return [texts.length ? <InfoIcon className="h-8 w-8" /> : null, texts];
  }, [clientForm, currentUser?.id, t]);

  const NoticeIcon = useMemo(() => {
    return approvalIcon || reviewedIcon || otherNoticesIcon;
  }, [approvalIcon, otherNoticesIcon, reviewedIcon]);

  const [hideNoticeBar, setHideNoticeBar] = useState(false);

  const [allFlaggedRisks, setAllFlaggedRisks] = useState<Risk[]>([]);
  useEffect(() => {
    if (clientForm) {
      RiskService.getRisks(clientForm?.clientId, { clientFormIds: [clientForm?.id], pageSize: 9999, pageNumber: 1 }).then((res) => {
        const allRisks = [...res.data, ...Object.values(actionRiskAndText)];
        const allActions = clientForm.form.sections.reduce((acc: FormAction[], section: FormSection) => {
          acc.push(...section.actions);
          return acc;
        }, []);
        setAllFlaggedRisks(
          allRisks.filter((x) => x.isValid).map((risk) => ({ ...risk, actionIndex: allActions.findIndex((x) => x.id === risk.actionId) })),
        );
      });
    }
  }, [actionRiskAndText, clientForm]);

  useEffect(() => {
    const handle = (event: QuestionRiskCreatedEvent) => {
      setAllFlaggedRisks((prev) => {
        const existingRiskIndex = prev.findIndex((risk) => risk.id === event.risk.id);

        if (existingRiskIndex !== -1) {
          return prev.map((risk, index) => (index === existingRiskIndex ? event.risk : risk));
        } else {
          return [...prev, event.risk];
        }
      });
    };
    EventSystem.listen('question-risk-created', handle);
    return () => EventSystem.stopListening('question-risk-created', handle);
  }, []);

  const onSetEffectiveDate = useCallback((value: Date | null) => {
    setClientForm((prev) => prev && { ...prev, effectiveDateUtc: value?.toISOString() ?? null });
  }, []);

  const onSetDueDate = useCallback((value: Date | null) => {
    setClientForm((prev) => prev && { ...prev, dueDateUtc: value?.toISOString() ?? null });
  }, []);

  const toHighlight = useMemo<ActionHighlightInfo | null>(() => {
    const parts = query.get('highlight')?.split(',');
    if (!parts || parts.length !== 3) {
      return null;
    }

    const formId = parts[0];
    let sectionId = parts[1];
    const actionId = parts[2];
    if (!sectionId) {
      sectionId = clientForm?.form.sections.find((x) => x.actions.find((a) => a.id === actionId))?.id || '';
    }

    return {
      formId,
      sectionId,
      sourceId: actionId,
    };
  }, [clientForm?.form.sections, query]);

  useEffect(() => {
    if (!clientForm) {
      return;
    }

    const doHighlight = () => {
      if (!formRenderer.current) {
        setTimeout(() => doHighlight, 100);
        return;
      }
      formRenderer.current?.highlight(toHighlight);
    };

    setTimeout(doHighlight, 2000);
  }, [clientForm, toHighlight]);

  useEffect(() => {
    const handler = (evt: { id: string; signed: boolean }) => {
      if (evt.id !== clientForm?.id) return;

      setClientForm(
        (prev) =>
          prev &&
          ({
            ...prev,
            distribution: {
              ...(prev as DocumentResponse).distribution,
              members: (prev as DocumentResponse).distribution.members.map((x) =>
                x.memberId !== currentUser?.id
                  ? x
                  : {
                      ...x,
                      status: evt.signed ? DistributionMemberStatus.Signed : DistributionMemberStatus.Acknowledged,
                      statusUtc: new Date().toISOString(),
                    },
              ),
            },
          } as DocumentResponse),
      );
    };

    EventSystem.listen('acknowledged', handler);
    return () => {
      EventSystem.stopListening('acknowledged', handler);
    };
  }, [clientForm?.id, currentUser?.id]);

  const onDistributionUpdated = useCallback((distribution: DistributionResponse) => {
    setClientForm(
      (prev) =>
        prev && {
          ...prev,
          distribution: distribution,
        },
    );
  }, []);

  useEffect(() => {
    const handler = (evt: DistributionResponse) => {
      onDistributionUpdated(evt);
    };

    EventSystem.listen('distribution-updated', handler);
    return () => {
      EventSystem.stopListening('distribution-updated', handler);
    };
  }, [onDistributionUpdated]);

  return (
    <div className="flex h-full min-h-full flex-col">
      {clientForm && (
        <TopNavPortal>
          {clientForm.form.type === FormType.Document && <DocumentBreadCrumb clientForm={clientForm} />}
          {clientForm.form.type !== FormType.Document && <FormBreadCrumb clientForm={clientForm} />}
        </TopNavPortal>
      )}
      <div className="flex h-full flex-col">
        {clientForm && (
          <FormHeader
            onRename={onRename}
            editMode={false}
            formProgress={progress}
            clientForm={clientForm}
            startDate={formStartDate}
            formStatus={formStatus}
            onSetDueDate={onSetDueDate}
            onStatusChanged={fetchForm}
            hideMainFormActions={!hasPermission(Roles.Employee) && !currentFormUser}
            allSteps={visibleSections}
            allStepInfos={clientForm?.sectionStatuses ?? []}
            currentStep={clientForm?.currentSteps[0]}
            onChecklistChanged={onChecklistChanged}
            associations={associations}
            onFileReplaced={fetchForm}
            onAccessTypeChange={onAccessTypeChange}
            onSetEffectiveDate={onSetEffectiveDate}
            scrolledToBottom={bottomDetectorVisible}
          />
        )}
        <div className="relative flex flex-1 overflow-y-auto overflow-x-hidden">
          <div>
            {clientForm && (
              <div className="relative h-full">
                <div
                  ref={leftPaneRef}
                  className={`sticky top-0 h-full w-[300px] overflow-y-auto border-r transition-[margin] duration-300 ${
                    leftNavCollapsed ? '-ml-[285px]' : 'mr-0'
                  }`}
                >
                  <ViewerLeftNav
                    viewOnly={true}
                    clientForm={clientForm}
                    fetchForm={fetchForm}
                    associations={associations}
                    onChecklistChanged={onChecklistChanged}
                    allFlaggedRisks={allFlaggedRisks}
                    onDistributionUpdated={onDistributionUpdated}
                  />
                </div>
                <ChevronIcon
                  onClick={() => setLeftNavCollapsed((prev) => !prev)}
                  type={leftNavCollapsed ? ChevronType.RIGHT : ChevronType.LEFT}
                  className={`hover:bg-gray-5 absolute -right-3 top-4 z-[40] h-6 w-6 rounded-full border bg-white transition duration-500 ease-in-out`}
                />
              </div>
            )}
          </div>
          {clientForm && (
            <>
              <div className={`relative flex flex-grow justify-between`}>
                <div
                  className={`flex flex-grow flex-col overflow-x-auto overflow-y-auto transition-[margin] duration-500 ${clientForm.form.isSystem ? '' : 'pb-8'}`}
                  style={{ maxWidth: docViewerMaxWidth }}
                >
                  {!hideNoticeBar && (reviewedText.length > 0 || approvalText.length > 0 || otherNoticesText.length > 0) && (
                    <div className="bg-gray-5 relative m-4 flex gap-4 rounded p-2">
                      <div>{NoticeIcon}</div>
                      <div className="flex flex-col justify-center">
                        <div>{reviewedText}</div>
                        <div>{approvalText}</div>
                        <div>{otherNoticesText}</div>
                      </div>

                      <XIcon className="absolute right-4 top-4 h-4 w-4" onClick={() => setHideNoticeBar(true)} />
                    </div>
                  )}

                  <FormRendererDesignContext.Provider
                    value={{
                      ...FormRendererDesignDefaults,
                    }}
                  >
                    {!viewDistributionLogs && (
                      <>
                        {!clientForm.form.isSystem && (
                          <FormRendererV2
                            initialMode={FormRendererMode.PreviewView}
                            actionRisks={actionRisks}
                            clientForm={clientForm}
                            renderAllSteps
                            stepStatuses={clientForm.sectionStatuses}
                            featureToggles={{
                              disableModeToggle: true,
                              enableTotalComments: true,
                            }}
                            isCommentsVisible={!commentsCollapsed}
                            allFlaggedRisks={allFlaggedRisks}
                            ref={formRenderer}
                          />
                        )}
                        {isDocumentFile && selectedFile && (
                          <DocumentFileViewer
                            key={`${selectedFile}${docViewerMaxWidth}`}
                            uri={selectedFile}
                            type={(clientForm as DocumentResponse).fileFormat}
                            style={documentViewerStyle}
                          />
                        )}
                        {bottomDetector}
                      </>
                    )}
                    {viewDistributionLogs && clientForm && (
                      <DistributionLogs
                        documentTitle={FormUtils.getDocumentTitle(clientForm as DocumentResponse)}
                        distribution={clientForm.distribution}
                      />
                    )}
                  </FormRendererDesignContext.Provider>
                </div>
              </div>
              <div className="relative h-full">
                <ChevronIcon
                  onClick={() => setCommentsCollapsed((prev) => !prev)}
                  type={!commentsCollapsed ? ChevronType.RIGHT : ChevronType.LEFT}
                  className={`hover:bg-gray-5 absolute -left-3 top-4 z-[40] h-6 w-6 rounded-full border bg-white transition duration-500 ease-in-out`}
                />

                <div
                  ref={rightPaneRef}
                  className={`mx-auto flex h-full w-[24rem] flex-shrink-0 flex-col overflow-auto transition-[margin] duration-300 ${
                    commentsCollapsed ? '-mr-[23rem]' : 'mr-0'
                  }`}
                >
                  <div className="bg-background-1 flex-grow border-l pt-2">
                    {!commentsCollapsed && (
                      <TabStrip evenTabs enableSticky>
                        <TabStrip.TabHeader id="comments" text={t('form:tabs.comments')} value={null}>
                          <TabStrip.TabHeader.Slot name="Trailing">
                            {unreadMainComments > 0 && (
                              <div className="text-dpm-12 flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-white">
                                {unreadMainComments}
                              </div>
                            )}
                          </TabStrip.TabHeader.Slot>
                        </TabStrip.TabHeader>
                        <TabStrip.TabContent forId="comments">
                          <div className="tab-scroll h-full overflow-y-auto">
                            <SignalRCommentProvider clientFormId={clientForm.id}>
                              <Comments formStepId={selectedCommentSection} clientFormId={clientForm.id} disabled={!!clientForm.originId} />
                            </SignalRCommentProvider>
                          </div>
                        </TabStrip.TabContent>
                      </TabStrip>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <RiskModal />
    </div>
  );
};

export default FormPreview;
